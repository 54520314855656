import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import LogoBranded from '~/shared/components/LogoBranded';
import {
  imagePropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import dataSelector from '~/shared/util/data-selector';

const LOGO_LINKS_TO_HOMEPAGE = true;

const containerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacings.kilo};
`;

const Container = styled('div')(containerStyles);

/**
 * SumupPartner component
 */
const SumUpPartner = ({
  href = '',
  logo = {},
  className = '',
  trackingContentEntry = {},
}) => {
  if (isEmpty(logo)) {
    return null;
  }
  return (
    <Container
      className={className}
      data-selector={dataSelector('section', 'sumup_partner')}
      data-elbcontext="component:sumup_partner"
    >
      <LogoBranded
        primaryLogoLinksToHomepage={LOGO_LINKS_TO_HOMEPAGE}
        partner={{ logo, url: href }}
        trackingContentEntry={trackingContentEntry}
      />
    </Container>
  );
};

SumUpPartner.propTypes = {
  /**
   * Link to external partner page
   */
  href: PropTypes.string,
  /**
   * External partner image
   */
  logo: imagePropType,
  /**
   * Additional class name
   */
  className: PropTypes.string,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default SumUpPartner;
